#demo {
  background-color: #ff2b5c;
  background-image: url("stars-3-inverse.5f5d1031.svg"), linear-gradient(-0.125turn, #ff2b5c, #ef2ac1);
  background-repeat: repeat, no-repeat;
  background-attachment: fixed, scroll;
}

.demo-container {
  position: relative;
  padding-bottom: 100%;
  background-image: linear-gradient(-0.125turn, #ff2b5c, #ef2ac1);
  background-attachment: fixed;
  border: 4px solid #fff;
  border-radius: 16px;
  overflow: hidden;
  contain: strict;
}

.demo-ring {
  position: absolute;
  top: 60px;
  left: 60px;
  right: 60px;
  bottom: 60px;
  animation: 1s alternate ease-in-out infinite;
}

.demo-ring img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.demo-horizontal {
  animation-name: demo-horizontal;
}

.demo-vertical {
  animation-name: demo-vertical;
}

.demo-diagonal {
  animation-name: demo-diagonal;
  animation-duration: 4s;
  animation-direction: normal;
}

@keyframes demo-horizontal {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(50%);
  }
}

@keyframes demo-vertical {
  0% {
    transform: translateY(-50%);
  }
  100% {
    transform: translateY(50%);
  }
}

@keyframes demo-diagonal {
  0%, 100% {
    transform: translate(50%, -50%);
  }
  25% {
    transform: translate(-50%, 50%);
  }
  50% {
    transform: translate(50%, 50%);
  }
  75% {
    transform: translate(-50%, -50%);
  }
}
/*# sourceMappingURL=index.1636abf4.css.map */
